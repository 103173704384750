import React from "react";
import Helmet from "react-helmet";
import styled, {createGlobalStyle} from "styled-components";
import Confetti from "react-dom-confetti";

const Global = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: monospace;
  }
`

const Container = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  background: repeating-linear-gradient(     45deg,     #DDD,     #DDD 1px,     #CCC 1px,     #CCC 20px   )
`;



function getNumeronymIteration(text, i) {
  const arrayText = Array.from(text);
  const left = i;
  const right = arrayText.length - i * 2;

  if (right > 1) {
    const inside = arrayText.splice(left, right);

    arrayText.splice(i, 0, inside.length.toString());

    return arrayText.join("");
  }

  return text;
}

class Numeronym extends React.Component {
  state = {
    i: 1
  };

  onClickHandle = () => {
    this.setState(state => {
      return {
        i: state.i + 1
      };
    });
  };

  render() {
    const text = getNumeronymIteration(this.props.text, this.state.i);

    const config = {
      angle: 90,
      spread: 59,
      startVelocity: 89,
      elementCount: 50,
      decay: 0.69
    };

    const fontWidth = Math.floor(150 / text.length);

    const StyledName = styled.p`
      text-align: center;
      font-size: ${fontWidth}vw;
      line-height: 1em;
      padding: 20px;
      color: white;
      background: red;
    `;
    return (
      <>
        <Confetti active={text === this.props.text} config={config} />
        <StyledName onClick={this.onClickHandle}>{text}</StyledName>
      </>
    );
  }
}

const WIP = styled.p`
  position: fixed;
  bottom: 0;
  background: black;
  padding: 1em;
  color: yellow;
  font-weight: bold;
`;

class RootIndex extends React.Component {
  render() {
    return (
      <Container>
        <Global></Global>
        <Helmet title={"Alex Alvarez Chinchilla"} />
        <Numeronym text="alex alvarez chinchilla" />
        <WIP>⚠️ 🚧 WORK IN PROGRESS 🚧 ⚠️</WIP>
      </Container>
    );
  }
}

export default RootIndex;
